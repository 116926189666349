<template>
  <div class="container">
    <div style="display: flex; justify-content: flex-end; margin: 10px" v-if="$route.path === '/address-manage'">
      <el-button round plain type="primary" @click="returnOrder">返回</el-button>
    </div>
    <div class="has">
      <el-row type="flex" justify="space-between" class="address-info font-s14">
        <el-col>您共创建了{{ addrList.length }}个地址</el-col>
        <div style="width: 150px" class="button-primary" @click="addAddr">新增收货地址</div>
      </el-row>
      <ul class="list-item">
        <li v-for="(item, index) in addrList" :key="index">
          <div>
            <span :span="12" class="font-s14">{{ item.receiver }}</span>
            <span class="button-primary font-s14" v-if="item.is_default">默认</span>
            <span :offset="20">
              <el-link @click="editAddr(item)" style="margin-right: 10px">编辑</el-link>
              <el-link @click="delAddr(item.addr_id)" style="margin-right: 10px">删除</el-link>
              <el-link @click="setDefault(item)">设为默认</el-link>
            </span>
          </div>
          <div>
            <span class="font-s14">收货人：{{ item.receiver }}</span>
            <span class="font-s14">手机号：{{ item.tel.substring(0, 3) }}****{{ item.tel.substring(7, 11) }}</span>
            <span class="font-s14">地址：{{ item.province }} {{ item.city }} {{ item.county }} {{ item.addr }}</span>
          </div>
        </li>
      </ul>
      <el-dialog width="670px" title="收货地址" :visible.sync="showDialog">
        <el-form ref="addrForm" :model="addrForm" label-width="120px" :rules="rules">
          <el-form-item label="收货人：" prop="receiver" size="small" style="width: 460px">
            <el-input v-model="addrForm.receiver"></el-input>
          </el-form-item>
          <el-form-item label="收货人电话：" prop="tel" size="small" style="width: 460px">
            <el-input v-model="addrForm.tel"></el-input>
          </el-form-item>
          <el-form-item label="地址：" size="small" required>
            <el-row type="flex" justify="start">
              <el-form-item prop="provinceId">
                <el-select
                  v-model="addrForm.provinceId"
                  placeholder="请选择"
                  size="small"
                  @change="selectChange(1)"
                  style="width: 120px"
                >
                  <el-option v-for="item in addrForm.provinceList" :key="item.key" :label="item.name" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="cityId">
                <el-select
                  size="small"
                  v-model="addrForm.cityId"
                  placeholder="请选择"
                  @change="selectChange(2)"
                  style="width: 120px"
                >
                  <el-option v-for="item in addrForm.cityList" :key="item.key" :label="item.name" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="countyId">
                <el-select
                  size="small"
                  v-model="addrForm.countyId"
                  placeholder="请选择"
                  style="width: 120px"
                  @change="selectChange(3)"
                >
                  <el-option v-for="item in addrForm.countyList" :key="item.key" :label="item.name" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" v-model="addrForm.townId" placeholder="请选择" style="width: 120px">
                  <el-option v-for="item in addrForm.townList" :key="item.key" :label="item.name" :value="item.key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
          </el-form-item>
          <el-form-item label="详细地址：" prop="addr" size="small" style="width: 500px">
            <el-input v-model="addrForm.addr"></el-input>
          </el-form-item>
          <el-button class="okButton" @click="saveAddr">确定</el-button>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
export default {
  data() {
    const validateTel = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      if (!/^1[3-9]\d{9}$/.test(value)) {
        return callback(new Error('手机号格式错误'))
      }
      callback()
    }
    return {
      addrList: [],
      showDialog: false,
      addrForm: {
        addr_id: '',
        receiver: '',
        tel: '',
        provinceId: '',
        cityId: '',
        countyId: '',
        townId: '',
        addr: '',
        is_default: false,
        provinceList: [],
        cityList: [],
        countyList: [],
        townList: [],
      },
      rules: {
        receiver: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
        tel: [{ required: true, validator: validateTel, trigger: 'blur' }],
        provinceId: [{ required: true, message: '请选择省份', trigger: 'blur' }],
        cityId: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        countyId: [{ required: true, message: '请选择', trigger: 'blur' }],
        addr: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.getAddrList()
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    returnOrder() {
      this.$router.push(this.user.next)
    },
    async getAddrList() {
      store.dispatch('order/getAddrList').then(res => {
        if (res.data.ret_code === 0) {
          this.addrList = res.data.data.addr_list
        }
      })
    },
    async setDefault(addrForm) {
      this.addrForm.addr_id = addrForm.addr_id
      this.addrForm.provinceId = addrForm.provinceID
      this.addrForm.cityId = addrForm.cityID
      this.addrForm.countyId = addrForm.countyID
      this.addrForm.townId = addrForm.townID
      this.addrForm.receiver = addrForm.receiver
      this.addrForm.tel = addrForm.tel
      this.addrForm.addr = addrForm.addr
      this.addrForm.is_default = true
      delete this.addrForm.provinceList
      delete this.addrForm.cityList
      delete this.addrForm.countyList
      delete this.addrForm.townList
      store.dispatch('order/addOrUpdateAddr', this.addrForm).then(res => {
        if (res.data.ret_code === 0) {
          window.location.reload()
        }
      })
    },
    async editAddr(addrForm) {
      this.addrForm.addr_id = addrForm.addr_id
      this.addrForm.provinceId = addrForm.provinceID
      this.addrForm.cityId = addrForm.cityID
      this.addrForm.countyId = addrForm.countyID
      this.addrForm.townId = addrForm.townID
      this.addrForm.receiver = addrForm.receiver
      this.addrForm.tel = addrForm.tel
      this.addrForm.addr = addrForm.addr
      this.addrForm.is_default = addrForm.is_default
      //省数据
      await store.dispatch('order/getProvince').then(res => {
        if (res.data.ret_code === 0) {
          this.addrForm.provinceList = res.data.data
        }
      })
      //市数据
      await store.dispatch('order/getCity', this.addrForm.provinceId).then(res => {
        if (res.data.ret_code === 0) {
          this.addrForm.cityList = res.data.data
        }
      })
      //县数据
      await store.dispatch('order/getCounty', this.addrForm.cityId).then(res => {
        if (res.data.ret_code === 0) {
          this.addrForm.countyList = res.data.data
        }
      })
      //乡数据
      await store.dispatch('order/getTown', this.addrForm.countyId).then(res => {
        if (res.data.data.length === 0) {
          this.addrForm.townList.push({
            key: 0,
            name: '请选择',
          })
        } else {
          this.addrForm.townList = res.data.data
        }
      })
      this.showDialog = true
    },
    async delAddr(addrId) {
      this.$confirm('确认删除此地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          //点击确定的操作(调用接口)
          store.dispatch('order/delAddr', addrId).then(res => {
            if (res.data.ret_code === 0) {
              this.$message.success(res.data.ret_msg)
              window.location.reload()
            } else {
              this.$message.error('删除失败！')
            }
          })
        })
        .catch(() => {
          //几点取消的提示
        })
    },
    async addAddr() {
      delete this.addrForm.addr_id
      const _this = this
      this.addrForm.townList.push({
        key: 0,
        name: '请选择',
      })
      this.$nextTick(() => {
        _this.$refs.addrForm.resetFields()
      })
      await store.dispatch('order/getProvince').then(res => {
        if (res.data.ret_code === 0) {
          this.addrForm.provinceList = res.data.data
        }
      })
      this.showDialog = true
    },
    async selectChange(flag) {
      if (flag === 1) {
        store.dispatch('order/getCity', this.addrForm.provinceId).then(res => {
          if (res.data.ret_code === 0) {
            this.addrForm.cityList = res.data.data
          }
        })
      } else if (flag === 2) {
        store.dispatch('order/getCounty', this.addrForm.cityId).then(res => {
          if (res.data.ret_code === 0) {
            this.addrForm.countyList = res.data.data
          }
        })
      } else if (flag === 3) {
        store.dispatch('order/getTown', this.addrForm.countyId).then(res => {
          if (res.data.ret_code === 0) {
            this.addrForm.townList = res.data.data
          }
        })
      }
    },
    async saveAddr() {
      this.$refs.addrForm.validate(valid => {
        if (valid) {
          if (this.addrForm.townId === '') {
            this.addrForm.townId = 0
            this.addrForm.xiang_id = this.addrForm.townId
          }
          delete this.addrForm.provinceList
          delete this.addrForm.cityList
          delete this.addrForm.countyList
          delete this.addrForm.townList
          delete this.addrForm.provinceList
          store.dispatch('order/addOrUpdateAddr', this.addrForm).then(res => {
            if (res.data.ret_code === 0) {
              this.$message.success(res.data.ret_msg)
              this.showDialog = false
              window.location.reload()
            } else {
              this.$message.error('创建失败！')
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  padding: 0;
  .has {
    background: #fff;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .address-info {
    background-color: #f5f5f5;
    height: 30px;
    width: 100%;
    line-height: 30px;
    color: #666666;
    padding: 0 0 0 10px;
  }
  ul.list-item {
    padding: 0;
    margin: 10px 0 20px 0;
    li {
      font-size: 14px;
      padding: 10px;
      border: 1px solid #e2e2e2;
      margin: 10px auto;
      div:first-child {
        display: flex;
        justify-content: flex-start;
        position: relative;
        span:first-child {
          font-size: 14px;
          margin-right: 5px;
        }
        span:nth-child(2) {
          height: 20px;
          font-size: 10px;
          padding: 0 5px 0 5px;
          line-height: 20px;
        }
        span:last-child {
          position: absolute;
          right: 0;
          font-size: 14px;
        }
      }
      div:last-child {
        span {
          width: 100%;
          display: block;
          margin: 10px 0;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
  .okButton {
    width: 373px;
    height: 50px;
    background: linear-gradient(180deg, #fe8418 0%, #ff6a24 100%);
    border-radius: 40px;
    color: #ffffff;
    margin-left: 20%;
  }
}
</style>
